import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Alert } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import '../styles/background_flip.css';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                {/*<input*/}
                {/*  type="checkbox"*/}
                {/*  checked={props.isSelected}*/}
                {/*  onChange={() => null}*/}
                {/*/>{" "}*/}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export class UserManagement extends Component {
    static displayName = UserManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            clientOptions: [],
            userId: '',
            client: null,
            active: false,
            firstName: null,
            lastName: null,
            officePhone: null,
            ext: null,
            homePhone: null,
            mobilePhone: null,
            email: null,
            success: undefined,
            userAlreadyExists: false,
            clientRequired: false,
            userRoles: [],
            userRoleData: null,
        };
        document.getElementsByTagName('html')[0].className = "background-simple-flip";
    }

    handleChange = (selected) => {
        console.log(selected);
        this.setState({
            userRoles: [selected]
        });
    };

    componentDidMount() {
        this.getClients();
        this.getRoles()
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.existingUser) {
                const userId = this.props.location.state.userId;
                this.setState({ userId: userId });
                this.getUserDetails(userId)
            }
        }
    }

    render() {
        //Quick update to add the transparent to the select.  General CSS doesn't hit it sadly. CDC 5/19 
        const customStyles = {
            control: (base, state) => ({
                ...base,
                background: "transparent",
            })
        };
        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>User Management</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs='6'>
                            <Typeahead
                                id='client'
                                labelKey='company'
                                onChange={(selected) => this.setState({ client: selected })}
                                selected={this.state.client}
                                options={this.state.clientOptions}
                                placeholder='Search Client'
                            />
                        </Col>
                        <Col xs='2' style={{ marginLeft: '20px' }}>
                            <Input
                                type='checkbox'
                                onChange={(e) => this.setState({ active: e.target.checked })}
                                checked={this.state.active}
                                name='active'
                            />
                            <label style={{ marginLeft: '10px' }}>Active</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.firstName}
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                name='firstName'
                                id='firstName'
                                placeholder='First Name'
                                valid={this.state.firstName ? true : false}
                                invalid={!this.state.firstName ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                name='lastName'
                                id='lastName'
                                placeholder='Last Name'
                                valid={this.state.lastName ? true : false}
                                invalid={!this.state.lastName ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='4'>
                            <Input
                                type='tel'
                                value={this.state.officePhone}
                                onChange={(e) => this.setState({ officePhone: e.target.value })}
                                name='officePhone'
                                id='officePhone'
                                placeholder='Office Phone'
                            />
                        </Col>
                        <Col xs='2'>
                            <Input
                                type='text'
                                value={this.state.ext}
                                onChange={(e) => this.setState({ ext: e.target.value })}
                                name='ext'
                                id='ext'
                                placeholder='Ext.'
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='4'>
                            <Input
                                type='tel'
                                value={this.state.homePhone}
                                onChange={(e) => this.setState({ homePhone: e.target.value })}
                                name='homePhone'
                                id='homePhone'
                                placeholder='Home Phone'
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='4'>
                            <Input
                                type='tel'
                                value={this.state.mobilePhone}
                                onChange={(e) => this.setState({ mobilePhone: e.target.value })}
                                name='mobilePhone'
                                id='mobilePhone'
                                placeholder='Mobile Phone'
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='email'
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                name='email'
                                id='email'
                                placeholder='Email'
                                valid={this.state.email ? true : false}
                                invalid={!this.state.email ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <ReactSelect
                                options={this.state.userRoleData}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={this.handleChange}
                                allowSelectAll={true}
                                value={this.state.userRoles}
                                valid={this.state.userRoles.length > 0 ? true : false}
                                invalid={this.state.userRoles.length === 0 ? true : false}
                                placeholder='Choose Role...'
                                className="games-dropdown-2"
                                styles={customStyles}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs='5'>
                            <Button
                                color='primary'
                                onClick={() => this.props.history.push('/UserSearch')}
                            >
                                Back to User Search
                            </Button>
                        </Col>
                        <Col xs='1'>
                            <Button
                                color='primary'
                                onClick={() => this.handleSave()}
                                disabled={!this.state.firstName || !this.state.lastName || !this.state.email ? true : false}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            {this.state.success === true && (
                                <Col xs='6'>
                                    <Alert color='success'>
                                        {this.state.userId ? 'User updated successfully!' : 'User added successfully!'}
                                    </Alert>
                                </Col>
                            )}
                            {this.state.success === false && (
                                <Col xs='6'>
                                    <Alert color='danger'>
                                        {this.state.userId ? 'Error updating User!' : 'Error adding User!'}
                                    </Alert>
                                </Col>
                            )}
                            {this.state.userAlreadyExists === true && (
                                <Col xs='6'>
                                    <Alert color='danger'>
                                        Email already in use. Please try again!
                                    </Alert>
                                </Col>
                            )}
                            {this.state.clientRequired === true && (
                                <Col xs='6'>
                                    <Alert color='danger'>
                                        Client is required.  Please select a client!
                                    </Alert>
                                </Col>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    async getClients() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/Clients/GetClientDropdown', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data });
    }

    async getUserDetails(id) {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Users/GetUserDetails?id=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        const client = [
            {
                id: data.clientId,
                company: data.client
            }
        ];
        this.setState({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            officePhone: data.officePhone,
            ext: data.ext,
            homePhone: data.homePhone,
            mobilePhone: data.mobilePhone,
            active: data.active,
            client: data.clientId === 0 ? null : client,
            userRoles: data.roles,
        })
    }

    async handleSave() {
        debugger
        const token = await authService.getAccessToken();
        const clientId = (this.state.client !== null && this.state.client !== undefined && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        const dto = {
            id: this.state.userId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            officePhone: this.state.officePhone,
            ext: this.state.ext,
            homePhone: this.state.homePhone,
            mobilePhone: this.state.mobilePhone,
            active: this.state.active,
            clientId: clientId,
            roles: this.state.userRoles,
        }

        let clientRequired = true;

        for (let i = 0; i < this.state.userRoles.length; i++) {
            let role = this.state.userRoles[i];
            if (role.value === 'Admin' || role.value === 'Tabb admin') clientRequired = false;
        }

        if (clientRequired && clientId === 0) {
            this.setState({ clientRequired: true });
        }
        else {
            this.setState({ clientRequired: false });

            if (this.state.userId) {
                if (!await this.getUserExists(dto.email, dto.id, token)) {
                    await this.editUser(dto, token);
                }
            }
            else {
                if (!await this.getUserExists(dto.email, '', token)) {
                    await this.createUser(dto, token);
                }
            }
        }
    }

    async editUser(dto, token) {
        const response = await fetch('api/Users/EditUser', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        this.setState({ success: data });
    }

    async createUser(dto, token) {
        const response = await fetch('api/Admin/CreateUser', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        this.setState({ success: data });
    }

    async getUserExists(email, id, token) {
        const response = await fetch(`api/Users/GetUserExists?userName=${email}&id=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        this.setState({ userAlreadyExists: data });
        return data;
    }

    async getRoles() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Admin/GetRolesAsync`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        var options = [];

        for (let i = 0; i < data.length; i++) {
            options.push({ value: data[i].name, label: data[i].name });
        }
        this.setState({ userRoleData: options });
    }
}