import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from '../util/util';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import '../styles/site.css';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export class TABBTools extends Component {
    static displayName = TABBTools.name;

    constructor(props) {
        super(props);

        this.state = {
            clientOptions: [],
            client: null,
            clientText: null,
            search: null,
            candidates: [],
            isAdmin: false,
            isTabbAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            testType: 'Selection',
            testLevel: 'MA',
            sheetOptions: [{ value: "EA Selection", label: "EA Selection", isWordDoc: false, level: "EA", type: "Selection" }, { value: "EA Developmental", label: "EA Developmental", isWordDoc: false, level: "EA", type: "Developmental" }, { value: "MA Selection", label: "MA Selection", isWordDoc: false, level: "MA", type: "Selection" }, { value: "MA Developmental", label: "MA Developmental", isWordDoc: false, level: "MA", type: "Developmental" }, { value: "HRE Selection", label: "Bloomingdale's HRE", isWordDoc: true, level: "HRE", type: "Selection" }, { value: "Callouts Only", label: "Callouts Only", isWordDoc: false, level: "Callouts", type: "Only" }, { value: "EA Selection", label: "EA Selection v2", isWordDoc: true, level: "EA", type: "Selection" }, { value: "EA Developmental", label: "EA Developmental v2", isWordDoc: true, level: "EA", type: "Developmental" }],
            sheetOptionsData: null,
            uploadSuccess: "",
            fileChosen: false,
            modalOpen: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.generateAssessment = this.generateAssessment.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";

    }

    onFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0], fileChosen: true });
    };

    onFileUpload = async () => {
        //Is the file that is uploaded a excell sheet?
        if (true) {
            this.setState({ loading: true });
            // Create an object of formData 
            this.state.uploadSuccess = "";
            const formData = new FormData();

            formData.append("formFile", this.state.selectedFile);
            formData.append("fileName", this.state.selectedFile.name);
            //formData.append("RecordId", this.state.client[0].clientRecordId);
            //formData.append("ClientCompany", this.state.client[0].clientCompany);

            // Update the formData object 
            //formData.append(
            //    "myFile",
            //    this.state.selectedFile,
            //    this.state.selectedFile.name
            //);

            // Details of the uploaded file 
            console.log(this.state.selectedFile);

            // Request made to the backend api 
            // Send formData object 


            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: formData,
                headers: !this.state.token ? {} : { 'Authorization': `Bearer ${this.state.token}` }
            };
            try {
                const response = await fetch('api/Clients/UploadFileTABB', requestOptions);
                const data = await response.json();
                if (data == "-1") {
                    this.setState({ uploadSuccess: "An error occurred!" })
                } else {
                    this.setState({ uploadSuccess: data + " records imported" })
                }
                console.log(data);
            } catch (e) {
                console.log(e);
            }
            this.setState({ loading: false });
            //fetch('api/Clients/UploadFile', requestOptions);
        } else {
            this.setState({ uploadSuccess: "clientRequired" });
        }


    };

    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.candidates.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }
    /*this.getAssessmentDocument(23707, 126, 23419, this.state.testType, this.state.testLevel)}
     getAssessmentDocument(assessmentId, recordId, candidateId, testType = "", testLevel = "") {*/
    generateAssessment(event, assessmentId, clientId, candidateId) {
        console.log(event.value);
        console.log(assessmentId);
        console.log(clientId);
        console.log(candidateId);
        var type = event.type;
        var level = event.level;
        var isWordDoc = event.isWordDoc;
        //var splitWords = event.value.split(" ");
        //if (splitWords.length >= 2) {
        //    this.getAssessmentDocument(assessmentId, clientId, candidateId, splitWords[1] , splitWords[0])
        //}
        this.getAssessmentDocument(assessmentId, clientId, candidateId, type, level, isWordDoc)
        //this.setState({ searchType: Number(event.target.value) });
    }
    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.candidates.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.candidates.length / pageSize) })
        }
    }

    async componentDidMount() {
        this.setWindowDimensions();
        const isAdmin = await AdminFunctions.isAdminAsync();
        const isTabbAdmin = await AdminFunctions.isTabbAdminAsync();
        this.setState({ isAdmin: isAdmin, isTabbAdmin: isTabbAdmin });
        if (isAdmin) {
            this.getClients();
        }
        else {
            this.getClientByUserId();
            this.showAllCandidates();
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchCandidates();
        }
    }

    render() {
        // Logic for displaying candidates
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = this.state.candidates.slice(indexOfFirstItem, indexOfLastItem);

        const renderItems = currentItems.map((candidate, i) => {
            return (
                <tr key={i}>
                    <td>{candidate.lastName}</td>
                    <td>{candidate.firstName}</td>
                    {this.state.isAdmin && (
                        <td>{candidate.client}</td>
                    )}
                    <td>{candidate.levelType}</td>
                    {this.state.isAdmin && (
                        <td className='td-button' id={`GenerateAssessment-${i}`}>
                            {/*{(candidate.assessments !== null && candidate.assessments.length > 0) && (*/}
                            {/*    <div>*/}
                            {/*        Assessment<br/> Complete*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {/*{(candidate.assessments === null || candidate.assessments.length < 1) && (*/}
                            <ReactSelect
                                options={this.state.sheetOptions}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={(event) => { this.generateAssessment(event, candidate.assessmentId, candidate.clientId, candidate.candidateId); }}
                                allowSelectAll={false}
                                value={this.state.sheetOptionsData}
                                valid={this.state.sheetOptions.length > 0 ? true : false}
                                invalid={this.state.sheetOptions.length === 0 ? true : false}
                                placeholder='Choose Report...'
                                className="games-dropdown-3"
                                isDisabled={this.state.loading ? true : false}
                            />
                            {/*)}*/}

                        </td>
                    )}
                </tr>
            )
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.candidates.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option value={number}>{number}</option>
            );
        });

        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>TABB Tools</h3>
                        </Col>
                    </Row>
                    {this.state.isTabbAdmin && (
                        <Row>
                            <Col>
                                <div>
                                    File Upload:
                                    <input type="file" onChange={this.onFileChange} style={{ marginLeft: '10px' }} />
                                    {this.state.fileChosen &&
                                        <button onClick={this.onFileUpload} style={{ marginTop: '5px', marginBottom: '20px' }}>
                                            Upload TABB Excel
                                        </button>
                                    }
                                    {this.state.uploadSuccess}

                                </div>
                            </Col>
                        </Row>
                    )}
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <Button*/}
                    {/*            color='primary'*/}
                    {/*            onClick={() => this.getAssessmentDocument(23707, 8866, 23419, this.state.testType, this.state.testLevel)}*/}
                    {/*            size='sm'*/}
                    {/*            style={{ cursor: 'pointer' }}*/}
                    {/*            disabled={this.state.loading ? true : false}*/}
                    {/*        >*/}
                    {/*            {this.state.width < DIMENSIONS.MD ? ('...') : ('Test-Generate')}*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*    <Col>*/}
                    {/*        <Button*/}
                    {/*            color='primary'*/}
                    {/*            onClick={() => this.getAssessmentDocument(23707, 126, 23419, this.state.testType, this.state.testLevel)}*/}
                    {/*            size='sm'*/}
                    {/*            style={{ cursor: 'pointer' }}*/}
                    {/*            disabled={this.state.loading ? true : false}*/}
                    {/*        >*/}
                    {/*            {this.state.width < DIMENSIONS.MD ? ('...') : ('Test-Generate-LOQ')}*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        Type: Selection  Developmental*/}
                    {/*        <Input*/}
                    {/*            type='text'*/}
                    {/*            onChange={(e) => this.setState({ testType: e.target.value })}*/}
                    {/*            name='search'*/}
                    {/*            id='search'*/}
                    {/*            onKeyPress={this.handleKeyPress}*/}
                    {/*            placeholder='Test Type (Developmental, Selection)'*/}
                    {/*            value={this.state.testType}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col>*/}
                    {/*        Level: EA MA*/}
                    {/*        <Input*/}
                    {/*            type='text'*/}
                    {/*            onChange={(e) => this.setState({ testLevel: e.target.value })}*/}
                    {/*            name='search'*/}
                    {/*            id='search'*/}
                    {/*            onKeyPress={this.handleKeyPress}*/}
                    {/*            placeholder='Test Level (EA, MA)'*/}
                    {/*            value={this.state.testLevel}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}




                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                name='search'
                                id='search'
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search Candidate Name, Position, or Status'

                            />
                        </Col>
                        <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                            {this.state.isAdmin && (
                                <Typeahead
                                    allowNew
                                    onChange={(selected) => this.setState({ client: selected })}
                                    onBlur={(e) => this.setState({ client: [e.target.value] })}
                                    selected={this.state.client}
                                    options={this.state.clientOptions}
                                    placeholder='Search Client'
                                    style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                    disabled={this.state.isAdmin === false ? true : false}
                                />
                            )}
                            {!this.state.isAdmin && (
                                <Typeahead
                                    allowNew
                                    onChange={(selected) => this.setState({ client: selected })}
                                    onBlur={(e) => this.setState({ client: [e.target.value] })}
                                    selected={this.state.clientOptions}
                                    options={this.state.clientOptions}
                                    placeholder='Search Client'
                                    style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                    disabled={this.state.isAdmin === false ? true : false}
                                />
                            )}
                        </Col>
                        <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    color='primary'
                                    onClick={() => this.searchCandidates()}
                                    style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap' } : { flex: '1', whiteSpace: 'nowrap' }}
                                    disabled={this.state.loading ? true : false}
                                >
                                    Search
                                </Button>
                                <Button
                                    color='primary'
                                    onClick={() => this.showAllCandidates()}
                                    style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                    disabled={this.state.loading ? true : false}
                                >
                                    Show All
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    
                    {this.state.candidates.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPage} onChange={this.handleChange}>
                                {renderPageNumbers}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            {/* <ul id="page-numbers">
                            {this.state.currentPage > 1 ? (
                                <li key={0} id={0} onClick={this.handleClick}>First</li>
                            ) : ( 
                                null 
                            )}
                            {this.state.currentPage > 1 ? (
                                <li key={this.state.currentPage - 1} id={this.state.currentPage - 1} onClick={this.handleClick}>Prev</li>
                            ) : (
                                null
                            )} 
                            <li>{this.state.currentPage} of {pageNumbers.length}</li>
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={this.state.currentPage + 1} id={this.state.currentPage + 1} onClick={this.handleClick}>Next</li>
                            ) : (
                                null
                            )}
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={-1} id={-1} onClick={this.handleClick}>Last</li>
                            ) : (
                                null
                            )} 
                            </ul> */}
                        </div>
                    )}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            Last Name
                                            <FaSort
                                                color={this.state.sort.column === 'lastName' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('lastName')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            First Name
                                            <FaSort
                                                color={this.state.sort.column === 'firstName' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('firstName')}
                                                className='sort-button'
                                            />
                                        </th>
                                        {this.state.isAdmin && (
                                            <th>
                                                Client
                                                <FaSort
                                                    color={this.state.sort.column === 'client' ? '#0a58ca' : 'black'}
                                                    onClick={() => this.handleSort('client')}
                                                    className='sort-button'
                                                />
                                            </th>
                                        )}
                                        <th>
                                            Level + Type
                                            <FaSort
                                                color={this.state.sort.column === 'levelType' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('levelType')}
                                                className='sort-button'
                                            />
                                        </th>
                                        {this.state.isAdmin && (
                                            <th>Generate Report</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true && this.state.loadingDocument === false ? (
                                        <tr>
                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                <Spinner color="primary" children='' />
                                            </td>
                                        </tr>

                                    ) : (
                                        <>
                                            {!this.state.noResults ? (
                                                <>
                                                    {renderItems}
                                                </>
                                            ) : (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                    <h5>No Results Found</h5>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {this.state.candidates.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px', marginBottom: '50px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPage} onChange={this.handleChange}>
                                {renderPageNumbers}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            {/* <ul id="page-numbers">
                            {this.state.currentPage > 1 ? (
                                <li key={0} id={0} onClick={this.handleClick}>First</li>
                            ) : ( 
                                null 
                            )}
                            {this.state.currentPage > 1 ? (
                                <li key={this.state.currentPage - 1} id={this.state.currentPage - 1} onClick={this.handleClick}>Prev</li>
                            ) : (
                                null
                            )} 
                            <li>{this.state.currentPage} of {pageNumbers.length}</li>
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={this.state.currentPage + 1} id={this.state.currentPage + 1} onClick={this.handleClick}>Next</li>
                            ) : (
                                null
                            )}
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={-1} id={-1} onClick={this.handleClick}>Last</li>
                            ) : (
                                null
                            )} 
                            </ul> */}
                        </div>
                    )}
                </Container>
            </div>
        )
    }

    // clients

    async getClients() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/Clients/GetClientDropdownTextAll', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data });
        this.setState({ token: token });
    }

    async getClientByUserId() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Clients/GetClientByUserId`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        // const client = [
        //     {
        //         id: data.id,
        //         company: data.company
        //     }
        // ];
        var clientOptionList = [data.company];
        this.setState({ client: data.company, clientOptions: clientOptionList });
    }

    // candidates

    async searchCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = await authService.getAccessToken();
        const query = this.state.search ? this.state.search : '';
        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }

        const response = await fetch(`api/Candidates/SearchCandidates?query=${query}&client=${client}&webViewable=false`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    async showAllCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = await authService.getAccessToken();
        const response = await fetch('api/Candidates/ShowAllCandidates?webViewable=false', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    //jsonToCsv(items) {
    //    const header = Object.keys(items[0]);
    //    const headerString = header.join(',');
    //    // handle null or undefined values here
    //    const replacer = (key, value) => value ?? '';
    //    const rowItems = items.map((row) =>
    //        header
    //            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
    //            .join(',')
    //    );
    //    // join header and body, and break into separate lines
    //    const csv = [headerString, ...rowItems].join('\r\n');
    //    return csv;
    //}

    //downloadBlob(content, filename, contentType) {
    //    // Create a blob
    //    var blob = new Blob([content], { type: contentType });
    //    var url = URL.createObjectURL(blob);

    //    // Create a link to download it
    //    var pom = document.createElement('a');
    //    pom.href = url;
    //    pom.setAttribute('download', filename);
    //    pom.click();
    //    URL.revokeObjectURL(blob)
    //}

    //async exportCandidatesToCSV() {
    //    this.setState({ loading: true, loadingDocument: false });
    //    const token = await authService.getAccessToken();
    //    const response = await fetch('api/Candidates/ExportCandidatesToCSV?webViewable=false', {
    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //    })
    //    const data = await response.json();
    //    console.log(data);
    //    const csv = this.jsonToCsv(data);
    //    console.log(csv);
    //    this.downloadBlob(csv, "CandidatesRawData.csv", "text/csv;charset=utf-8;");
    //    this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    //}
    async exportData() {
        this.setState({ loading: true, loadingDocument: false });
        const token = await authService.getAccessToken();
        const query = this.state.search ? this.state.search : '';
        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }

        const response = fetch(`api/Candidates/ExportCandidatesToCSV?query=${query}&client=${client}&webViewable=false`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        
        //const data = await response.json();
        this.setState({ loading: false });
    }


    async getDocument(recordId) {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Candidates/GetDocument?recordId=${recordId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.downloadFile(data.documentBase64, data.documentTitle);
    }

    async getAssessmentDocuments(assessments) {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Candidates/GetAssessmentDocuments`, {
            method: "POST",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(assessments)
        })
        const data = await response.json();
        this.downloadFile(data.documentBase64, data.documentTitle);
    }

    async getAssessmentDocument(assessmentId, recordId, candidateId, testType = "", testLevel = "", isWordDoc = false) {
        try {
            this.setState({ loading: true, loadingDocument: true });
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Document/GetAssessmentDocument?assessmentId=${assessmentId}&clientId=${recordId}&candidateId=${candidateId}&type=${testType}&level=${testLevel}&isWordDoc=${isWordDoc}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            const data = await response.json();
            this.downloadFile(data.documentBase64, data.filename);
            this.setState({ loading: false, loadingDocument: false });
        } catch (ex) {
            this.setState({ loading: false, loadingDocument: false });
        }
        
    }

    downloadFile(file, name) {
        const blob = this.base64ToBlob(file);
        const uriContent = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', uriContent);
        link.setAttribute('download', name);
        const event = new MouseEvent('click');
        link.dispatchEvent(event);
    }

    base64ToBlob = (file) => {
        const binaryString = window.atob(file);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/octet-stream' });
        return blob;
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.candidates;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            candidates: data,
            sort: {
                column,
                direction
            }
        })
    }
}