import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            user: undefined,
            isAdmin: false
        }
    }

    async componentDidMount() {
        const user = await authService.getUser();
        this.setState({ user: user });
        if (user) {
            const isAdmin = await AdminFunctions.isAdminAsync();
            this.setState({ isAdmin: isAdmin });
            document.getElementsByTagName('html')[0].className = "background-navigation";
        }
        else {
            document.getElementsByTagName('html')[0].className = "background-title";
        }
    }
    render() {
        return (
            <div>
                {this.state.user ? (
                    <ul style={{ fontSize: '24px', paddingTop: '10%' }}>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/UserSearch'
                            hidden={!this.state.isAdmin ? true : false}
                        >
                            User Search
                        </NavLink>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/CandidateSearch'
                        >
                            Candidate Search
                        </NavLink>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/TABBTools'
                            hidden={!this.state.isAdmin ? true : false}
                        >
                            TABB Tools
                        </NavLink>
                        {/*<NavLink className="nav-button"*/}
                        {/*    tag={Link}*/}
                        {/*    to='/DataExport'*/}
                        {/*    hidden={!this.state.isAdmin ? true : false}*/}
                        {/*>*/}
                        {/*    Data Export*/}
                        {/*</NavLink>*/}
                        {/*<NavLink className="nav-button"*/}
                        {/*    tag={Link}*/}
                        {/*    to='/Downloads'*/}
                        {/*    hidden={!this.state.isAdmin ? true : false}*/}
                        {/*>*/}
                        {/*    Downloads*/}
                        {/*</NavLink>*/}
                        {/*'### ToDo Hidden for now as it doens't quite work*/}
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/ClientLogos'
                            hidden={!this.state.isAdmin ? true : false}
                        >
                            Client Management
                        </NavLink>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/FAQ'
                        >
                            FAQ
                        </NavLink>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/TOU'
                        >
                            Terms of Use
                        </NavLink>

                        {/* <NavLink
                tag={Link}
                to='/Admin/Roles'
                hidden={!this.state.isAdmin ? true : false}
            >
                Roles
            </NavLink> */}
                        {/* <NavLink
              tag={Link}
              to='/TemplateManagement'
            >
              Template Management
            </NavLink> */}
                    </ul>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
