import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { UserSearch } from './components/UserSearch';
import { UserManagement } from './components/UserManagement';
import { CandidateSearch } from './components/CandidateSearch';
import { AdminReportTABB } from './components/AdminReportTABB';
import { TABBTools } from './components/TABBTools';
import { DataExport } from './components/DataExport';
import { Downloads } from './components/Downloads';
import { ClientLogos } from './components/ClientLogos';
import { FAQ } from './components/FAQ';
import { TOU } from './components/TOU';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Counter } from './components/Counter';
import { FetchData } from './components/FetchData';

import './custom.css'
import { Roles } from './components/admin/Roles';
import { CreateRole } from './components/admin/CreateRole';
import { RoleDetails } from './components/admin/RoleDetails';
import { TemplateManagement } from './components/TemplateManagement';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

const isProduction = process.env.NODE_ENV === 'production';

export default class App extends Component {
    static displayName = App.name;

  render () {
    return (
      <CacheBuster
            currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
    >
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/Home' component={Home} />
        <Route path='/counter' component={Counter} />
        <AuthorizeRoute path='/fetch-data' component={FetchData} />
        <AuthorizeRoute path='/UserSearch' component={UserSearch}/>
        <AuthorizeRoute path='/UserManagement' component={UserManagement}/>
                <AuthorizeRoute path='/CandidateSearch' component={CandidateSearch} />
                <AuthorizeRoute path='/TABBTools' component={TABBTools} />
                <AuthorizeRoute path='/DataExport' component={DataExport} />
                <AuthorizeRoute path='/Downloads' component={Downloads} />
                <AuthorizeRoute path='/AdminReportTABB' component={AdminReportTABB} />
                <AuthorizeRoute path='/ClientLogos' component={ClientLogos} />
                <AuthorizeRoute path='/FAQ' component={FAQ} />
                <AuthorizeRoute path='/TOU' component={TOU} />
        <AuthorizeRoute path='/TemplateManagement' component={TemplateManagement}/>
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <AuthorizeRoute exact path='/admin/roles' component={Roles}/>
        <AuthorizeRoute exact path='/admin/roles/create' component={CreateRole}/>
        <AuthorizeRoute exact path='/admin/roles/details' component={RoleDetails}/>
      </Layout>
      </CacheBuster>
    );
  }
}

