import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';
import { Container, Row, Col } from 'reactstrap';
import { components } from "react-select";
import '../styles/site.css';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export class Downloads extends Component {
    static displayName = Downloads.name;

    constructor(props) {
        super(props);

        this.state = {
            clientOptions: [],
            client: null,
            clientText: null,
            search: null,
            candidates: [],
            isAdmin: false,
            isTabbAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            testType: 'Selection',
            testLevel: 'MA',
            sheetOptions: [{ value: "EA Selection", label: "EA Selection" }, { value: "EA Developmental", label: "EA Developmental" }, { value: "MA Selection", label: "MA Selection" }, { value: "MA Developmental", label: "MA Developmental" }, , { value: "HRE Selection", label: "HRE" }, { value: "Callouts Only", label: "Callouts Only" }],
            sheetOptionsData: null,
            uploadSuccess: "",
            fileChosen: false,
            modalOpen: false,
            files: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";

    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }
    
    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.candidates.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.candidates.length / pageSize) })
        }
    }

    async componentDidMount() {
        this.setWindowDimensions();
        const isAdmin = await AdminFunctions.isAdminAsync();
        const isTabbAdmin = await AdminFunctions.isTabbAdminAsync();
        this.setState({ isAdmin: isAdmin, isTabbAdmin: isTabbAdmin });
        this.showDownloads();
        if (isAdmin) {
            
        }
        else {

        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    render() {

        const renderFiles = this.state.files.map(file => {
            return (
                <tr>
                    <td style={{ border: '1px solid #000', padding: '5px' }}>
                        {new Date(file.createdDate).toLocaleDateString('en-US')}
                    </td>
                    <td style={{ border: '1px solid #000', padding: '5px' }}>
                        {file.createdBy}
                    </td>
                    <td style={{ border: '1px solid #000', padding: '5px' }}>
                        {file.clientName}
                    </td>
                    <td style={{ border: '1px solid #000', padding: '5px' }}>
                        {file.candidateName}
                    </td>
                    <td style={{ border: '1px solid #000', padding: '5px' }}>
                        {file.scoreGroups}
                    </td>
                    <td style={{ border: '1px solid #000', padding: '5px' }}>
                        {file.allowDownload && (
                            <a href={'api/document/getexportdownload?filename=' + file.filePath} download>Download</a>
                        )}
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Downloads</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.files.length !== 0 && (
                                <table>
                                <thead>
                                        <tr>
                                            <th style={{textAlign: 'center'}}>Export Date</th>
                                            <th style={{ textAlign: 'center' }}>Exported By</th>
                                            <th style={{ textAlign: 'center' }}>Client</th>
                                            <th style={{ textAlign: 'center' }}>Candidate</th>
                                            <th style={{ textAlign: 'center' }}>Score Groups</th>
                                            <th style={{ textAlign: 'center' }}>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderFiles}
                                    </tbody>
                                </table>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    async showDownloads() {
        this.setState({ loading: true, loadingDocument: false });
        const token = await authService.getAccessToken();

        const response = await fetch(`api/Candidates/GetExportList`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 200) {
            const data = await response.json();
            this.setState({ files: data, loading: false });
        } else {
            this.setState({ loading: false });
        }
    }
}