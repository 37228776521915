import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'

// const FetchData = () => {
//   const [loading, setLoading] = useState(true);
//   const [forecasts, setForecasts] = useState([]);
//   useEffect(() => {
//     (async () => {
//       const token = await authService.getAccessToken();
//       const response = await fetch('weatherforecast', {
//         headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
//       });
//       const data = await response.json();
//       setForecasts(data);
//       setLoading(false);
//     })();
//   }, []);

//   function renderForecastsTable(forecasts) {
//     return (
//       <table className="table table-striped" aria-labelledby="tableLabel">
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Temp. (C)</th>
//             <th>Temp. (F)</th>
//             <th>Summary</th>
//           </tr>
//         </thead>
//         <tbody>
//           {forecasts.map(forecast => (
//             <tr key={forecast.date}>
//               <td>{forecast.date}</td>
//               <td>{forecast.temperatureC}</td>
//               <td>{forecast.temperatureF}</td>
//               <td>{forecast.summary}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     );
//   }
//   function renderLoadingMessage() {
//     return (<p><em>Loading...</em></p>);
//   }

//   return (
//     <div>
//       <h1 id="tableLabel">Weather forecast</h1>
//       <p>This component demonstrates fetching data from the server.</p>
//       {loading ? renderLoadingMessage() : renderForecastsTable(forecasts)}
//     </div>
//   );
  
// }

// export default FetchData


export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateWeatherData();
  }

  static renderForecastsTable(forecasts) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
            <tr key={forecast.date}>
              <td>{forecast.date}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);

    return (
      <div>
        <h1 id="tabelLabel" >Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
      </div>
    );
  }

  async populateWeatherData() {
    debugger
    const token = await authService.getAccessToken();
    const response = await fetch('weatherforecast', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
