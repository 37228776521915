import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';

export class TOU extends Component {
    static displayName = TOU.name;

    constructor(props) {
        super(props);

        this.state = {
            user: undefined,
            isAdmin: false
        }
    }

    async componentDidMount() {
        const user = await authService.getUser();
        this.setState({ user: user });
        if (user) {
            const isAdmin = await AdminFunctions.isAdminAsync();
            this.setState({ isAdmin: isAdmin });
            document.getElementsByTagName('html')[0].className = "background-navigation";
        }
        else {
            document.getElementsByTagName('html')[0].className = "background-title";
        }
    }
    render() {
        //const styles = {
        //    question: {

        //    },
        //    answer: {

        //    },
        //    tableBorder: {
        //        border: "1px solid"
        //    }
        //};
        return (
            <div>
                {this.state.user ? (
                    <ul style={{ fontSize: '16px' }}>
                        <h3>TERMS OF USE AGREEMENT</h3>
                        <p className="header">1. SCOPE</p>
                        <p className="answer">Psychological Associates ("PA") maintains for certain clients an online platform ("Q4 Compass") featuring information on and access to our products and services. PA may provide access for you pursuant to the terms and conditions of this Terms of Use Agreement ("Agreement"). This Agreement governs your access to and use of the Q4 Compass. Your access and/or utilization of the Q4 Compass constitutes acceptance of these terms and conditions. If you do not agree with these terms and conditions, you may not access or utilize the Q4 Compass. <br /><br />
                            Because this agreement is subject to change, and you will be bound by such changes, it is in your interest to periodically review this agreement.</p>
                        <p className="header">2. TERM </p>
                        <p className="answer">The term of this Agreement is effective upon issuance by PA of an authorized user login ID and shall continue until terminated by either party. You agree that all registration information is accurate and truthful. You also agree to immediately inform PA of any changes that may affect your eligibility as an authorized user of the Q4 Compass. PA shall incur no obligation to provide ongoing access to the Q4 Compass and may cease operations of the Q4 Compass at any time and for any reason.</p>
                        <p className="header">3. PERMISSABLE USE </p>
                        <p className="answer">You and your authorized employees may access, download, and utilize material made available to you through the Q4 Compass for internal business purposes only, in accordance with the terms of this Agreement. Access to the Q4 Compass is granted at the sole discretion of PA and may be terminated at any time.
                            Access to the Q4 Compass is permitted by username and password. You are responsible for protecting the confidentiality of the username and password registered to your account. Further, you agree not to share or disclose your username or password with any third parties, and you accept complete responsibility for actions which occur under your username. If you believe your username and/or password have been compromised, contact PA immediately. If you are terminated or resign from your current employer and are no longer authorized to access the Q4 Compass, contact PA so that we may disable your username and password. <br /><br />
                            You agree to use PA products and services in a manner consistent with their intended use, and within the guidelines of all applicable employment laws and regulations, and all professional, ethical, and regulatory guidelines. You agree that you are responsible for complying with all applicable privacy, data transfer, and data security laws, and agree to abide by such laws in connection with your use of the Q4 Compass.<br /><br />
                            Information seen or obtained through the Q4 Compass may be considered confidential.  You agree to limit access to confidential information to those who have a need to know this confidential information.
                        </p>
                        <p className="header">4. PROTECTION</p>
                        <p className="answer">All information provided through the Q4 Compass is the proprietary property of PA. You agree not to change, alter, or modify the physical or electronic characteristics or content of assessments, reports, or any other aspect of the Q4 Compass or materials downloaded from the Q4 Compass. You may not transfer, share, disclose, or distribute information to third parties.  </p>
                        <p className="header">5. CHANGES  </p>
                        <p className="answer">PA reserves the right, in its sole discretion, to modify, add, or remove any portion of this Agreement, in whole or in part, at any time. <br /><br />
                            Further, PA may modify, upgrade, or discontinue any aspect of the Q4 Compass at any time, including, but not limited to your access, available content, and services offered. PA may also impose limits on certain features of the Q4 Compass and/or restrict access to the Q4 Compass without notice or liability.<br /><br />
                            Your continued use of the Q4 Compass constitutes acceptance of all changes.
                        </p>
                        <p className="header">6. LIABILITY</p>
                        <p className="answer">PA strives to provide current and accurate information through the Q4 Compass, but you acknowledge that the Q4 Compass may contain omissions or errors. <br /><br />
                            PA makes no representations or warranties regarding the operation of the Q4 Compass. Any liability of PA for loss or damage relating to this agreement or your use or inability to use the Q4 Compass shall be strictly limited to fees paid for the products and services involved.
                        </p>
                        <p className="header">7. CONFIDENTIALITY</p>
                        <p className="answer">The evaluations and reports available on the Q4 Compass are highly confidential, and You agree that neither they nor their contents will be disclosed to the subjects of these evaluations and reports without the prior written approval of PA.  You agree to defend, indemnify, and hold harmless, PA, its owners, officers, directors, employees, and consultants, against any claim, cause of action, damage, loss, or cost resulting from the disclosure of any evaluation or report, or any portion thereof, obtained from the Q4 Compass to the subject(s) thereof.</p>
                        <p className="header">8. GENERAL </p>
                        <p className="answer">This agreement is governed by the laws of the State of Missouri. It constitutes the complete agreement between you and PA regarding your status as an authorized user of the Q4 Compass. </p>
                        <p className="verno">63230572.1</p>


                    </ul>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
